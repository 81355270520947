/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from "./schema-org"
function SEO({
  title,
  description,
  keywords,
  imagePath,
  pathname,
  type,
  lang,
  isBlogPost,
}) {
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
        titleTemplate,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            author
            siteUrl: url
            defaultImage: image
            titleTemplate
          }
        }
      }
    `
  )

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${imagePath || defaultImage}`,
    url: `${siteUrl}${pathname || "/"}`,
    type: type || "website",
    keywords: [...keywords, "Winsome在學", "前端工程師", "讀書"].join(`, `),
  }

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={seo.title}
        titleTemplate={titleTemplate}
      >
        {/* meta */}
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keywords} />
        {/* meta END */}
        {/* fb Open Graph  */}
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content={seo.type} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        {/* fb Open Graph END */}
        {/* twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:creator" content={siteMetadata.author} /> */}
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        {/* twitter END */}
        {/* 粉絲頁 */}
        <div id="fb-root"></div>
        <script async={true} defer={true} crossOrigin="anonymous" src="https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v8.0&appId=1915294945174072&autoLogAppEvents=1" />
        {/* 粉絲頁 END */}
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={seo.url}
        title={seo.title}
        image={seo.image}
        description={seo.description}
        // datePublished={datePublished}
        canonicalUrl={seo.url}
        // author={seo.author}
        // organization={seo.organization}
        defaultTitle={seo.title}
      />
    </Fragment>
  )
}

SEO.defaultProps = {
  lang: `zh-Hant`,
  meta: [],
  description: ``,
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
